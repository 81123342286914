<ion-header>
  <ion-toolbar>
    <ion-title>{{odometer ? 'Odometer' : 'Dashboard'}} Photo Guide</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content *ngIf="odometer">
  <div class="ion-padding w-full h-screen bg-gray-50 bg-bottom bg-no-repeat bg-contain ">
    <ion-text class="text-left ml-6 text-md text-gray-500"> Examples:</ion-text>
    <div class="flex flex-col justify-center items-center py-4">
      <img src="/assets/odometer-one.png" alt="">
      <img class="mt-4" src="/assets/odometer-two.png" alt="">
    </div>

    <ion-card>
      <div class="p-4">
        <ion-text>
          <p class="text-lg text-black mt-5">Positioning</p>
          <p class="text-sm text-gray-500">Position the camera to have a clear view of your vehicle's odometer.</p>
        </ion-text>

        <ion-text>
          <p class="text-lg text-black mt-5">Composition</p>
          <p class="text-sm text-gray-500">Only the odometer display and parts of the speedometer (if applicable) should be visible.</p>
        </ion-text>

        <ion-text>
          <p class="text-lg text-black mt-5">Lighting</p>
          <p class="text-sm text-gray-500">Find a well-lit area, making sure the odometer display is clear and without glare.</p>
        </ion-text>

        <ion-text>
          <p class="text-lg text-black mt-5 ">Focus</p>
          <p class="text-sm text-gray-500">The numbers on the odometer display must be clear and legible in the photo.</p>
        </ion-text>
      </div>
      <div class="h-16"></div>
    </ion-card>
  </div>
</ion-content>

<ion-content *ngIf="dashboard">
  <div class="ion-padding w-full h-screen bg-gray-50 btla3g-bottom bg-no-repeat bg-contain ">
    <ion-text class="text-left ml-6 text-md text-gray-500"> Examples:</ion-text>
    <div class="flex flex-col justify-center items-center py-4">
      <img src="/assets/dashboard1.png" alt="">
      <img class="mt-4" src="/assets/dashboard2.png" alt="">
    </div>

    <ion-card>
      <div class="p-4">
        <ion-text>
          <p class="text-lg text-black mt-5">Positioning</p>
          <p class="text-sm text-gray-500">Capture both the odometer and dashboard. Steering wheel must be visible.</p>
        </ion-text>

        <ion-text>
          <p class="text-lg text-black mt-5">Composition</p>
          <p class="text-sm text-gray-500">Odometer must be visible with other dashboard features like speedometer, fuel gauge, and indicators.</p>
        </ion-text>

        <ion-text>
          <p class="text-lg text-black mt-5">Lighting</p>
          <p class="text-sm text-gray-500">Choose a well-lit area to avoid glare and ensure photo clarity.</p>
        </ion-text>

        <ion-text>
          <p class="text-lg text-black mt-5 ">Focus</p>
          <p class="text-sm text-gray-500">Make sure the entire dashboard, including the odometer and the steering wheel, is in focus before taking the photo.</p>
        </ion-text>
      </div>

      <div class="h-16"></div>
    </ion-card>
  </div>
</ion-content>

<div class="flex justify-center items-center">
  <ion-button (click)="dismissModal()">
    GO TO PICTURE SUBMISSION
  </ion-button>
</div>
