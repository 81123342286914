<ion-router-outlet id="main-content"></ion-router-outlet>
<ion-content>
  <div class="bg-gray-50 bg-top bg-no-repeat bg-contain h-screen">
    <app-nav-bar [title]="'Welcome'"> </app-nav-bar>
    <div *ngIf="isLoading" class="flex justify-center items-center h-screen">
      <ion-spinner></ion-spinner>
      <ion-text class="text-slate-700 text-lg ml-2"> Application loading... </ion-text>
    </div>
    <div *ngIf="!isLoading && !tokenError" class="flex items-center justify-center px-4 h-5/6">
      <div class="text-left flex justify-center flex-col">
        <div class="h-16"></div>
        <img src="/assets/Odometerodometer.svg" alt="Welcome Image" class="mx-auto mb-4">
        <h1 class="text-5xl font-semibold text-slate-700 text-center">Welcome!</h1>
        <p class="text-slate-600 py-4 text-center">You’re about to submit your vehicle’s odometer reading. Press start to proceed
          and follow the instructions on next pages.</p>
        <ion-text *ngIf="error" class="mb-4 text-sm text-center text-red-400">
          {{ error }}
        </ion-text>
        <ion-card *ngIf="!error" class="shadow-none w-full self-center">
          <ion-card-content>
            <ion-item lines="none" class="border-0">
              <ion-label class="text-slate-500 text-sm">Vehicle Model</ion-label>
              <ion-text class="text-slate-700">{{vehicle.vehicleModel}}</ion-text>
            </ion-item>
            <ion-item lines="none" class="border-0">
              <ion-label class="text-slate-500 text-sm">Model Year</ion-label>
              <ion-text class="text-slate-700">{{vehicle.modelYear}}</ion-text>
            </ion-item>
            <ion-item lines="none" class="border-0">
              <ion-label class="text-slate-500 text-sm">VIN</ion-label>
              <ion-text class="text-slate-700">{{vehicle.vinOEM}}</ion-text>
            </ion-item>
          </ion-card-content>
        </ion-card>
        <ion-button  *ngIf="!error" (click)="start()" class="shadow-none font-normal">
          Start
          <ion-icon class="ml-2" name="arrow-forward-outline"></ion-icon>
        </ion-button>

        <p class="text-center text-sm text-gray-400 pt-4">Incorrect information? <a href="mailto:support@clearroad.com" class="underline text-blue-600">Contact Customer Support</a></p>
        <div class="text-center w-full flex flex-col justify-center items-center pt-5">
          <p class="text-gray-400 text-sm">Powered by</p>
          <div class="flex justify-center items-center w-1/5 pt-5">
            <img src="/assets/udot-logo.png" alt="Logo" class="mx-auto">
            <img src="/assets/clearroad-logo.svg" alt="Logo" class="mx-auto">
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="tokenError" class="text-center flex justify-center flex-col h-5/6 px-4">
      <img src="/assets/attention-icon.svg" alt="Welcome Image" class="mx-auto mb-4">
      <h2 class="text-3xl font-semibold text-slate-700 text-center">Link used or expired</h2>
      <ion-card class="shadow-none w-full self-center">
        <ion-card-content>
          <ion-text class="text-slate-600 py-4">
            <p>This link has been used or has expired.</p>
            <p> You will receive an email with a new link to submit your odometer reading.</p>
          </ion-text>
        </ion-card-content>
      </ion-card>
    </div>
  </div>
</ion-content>
