import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CameraOutputData } from './camera/camera.component';

interface CapturedImageData {
  dashboard: CameraOutputData | null;
  odometer: CameraOutputData | null;
}
@Injectable({
    providedIn: 'root'
})
export class CameraService {

  private capturedImageSubject = new BehaviorSubject<CapturedImageData>({
    dashboard: null,
    odometer: null
  });
   capturedImage$ = this.capturedImageSubject.asObservable();

    constructor() { }

    async checkCameraPermission(): Promise<PermissionState|'unknown'> {
        try {
            const perm = await navigator.permissions.query({
                name: 'camera'
            } as unknown as PermissionDescriptor); // because 'camera' may not impl. but we have a fallback.
            return perm.state;
        }
        catch (e) {
            // ... camera permission is not implemented on this browser version.
            return 'unknown';
        }
    }

    // @warning requires camera permission first to not have an empty list of camera! cf. https://stackoverflow.com/questions/60297972/navigator-mediadevices-enumeratedevices-returns-empty-labels
    // @note it will request the perm because of getUserMedia call.
    async listCameraDevices(): Promise<(MediaDeviceInfo & { isEnvironmentDefault: boolean })[]> {
        let _defaultDeviceId: string | undefined = undefined;

        // First get the default environment device.
        try {
            // Request camera usage, ideally for environment camera.
            const stream = await navigator.mediaDevices.getUserMedia({
                video: {
                    facingMode: { ideal: "environment" }
                },
                audio: false
            });

            // ... camera permission has been granted.
            // ... no exception thrown, camera permission granted.

            // Get the device id.
            const deviceId = stream.getVideoTracks()[0].getSettings().deviceId;

            // Set as default device.
            _defaultDeviceId = deviceId;

            // Close the stream.
            let tracks = stream.getTracks();
            tracks.forEach(track => track.stop());
        }
        catch (e) {
            // ... camera permission was denied, or some issue with camera api has happened.
            console.error(e);
            return [];
        }

        // Then list all the devices and specify the default one.
        try {
            // List all devices.
            const devices = await navigator.mediaDevices.enumerateDevices(); // @warning may throw! + requires https!
            const videoInputDevices = devices.filter(device => device.kind === 'videoinput');

            // For each one, check if it is the default device.
            const videoInputDevicesWithDefault = videoInputDevices.map(device => {
                return {
                    ...device,
                    // @note somehow Brave browser doesn't list properties from when using console on the full list
                    // (due to proxy obj?), so we manually select the props as well.
                    deviceId: device.deviceId,
                    groupId: device.groupId,
                    kind: device.kind,
                    label: device.label,
                    isEnvironmentDefault: device.deviceId === _defaultDeviceId
                };
            });

            // Return the list of devices.
            return videoInputDevicesWithDefault;
        }
        catch (e) {
            // ... camera permission was denied, or some issue with camera api has happened.
            console.error(e);
            return [];
        }
    }

    async requestCameraPermission(): Promise<PermissionState|'unknown'> {
        try {
            // Request camera usage.
            const stream = await navigator.mediaDevices.getUserMedia({
                video: true,
                audio: false
            });

            // ... camera permission has been granted.
            // ... no exception thrown, camera permission granted.

            // Close the stream.
            let tracks = stream.getTracks();
            tracks.forEach(track => track.stop());

            // Return granted.
            return 'granted';
        }
        catch (e) {
            // ... camera permission was denied (or camera API compat issue, very unlikely).
            return 'denied';
        }
    }

    // @warning may throw due to compat issue (unlikely? TBT).
    async startStream(videoElement: HTMLVideoElement, cameraDevices: (MediaDeviceInfo & { isEnvironmentDefault: boolean })[]): Promise<MediaStream> {
        // Request camera usage.
        const stream = await navigator.mediaDevices.getUserMedia({
            video: {
                deviceId: cameraDevices.find(device => device.isEnvironmentDefault)?.deviceId,
                facingMode: { ideal: "environment" }
            },
            audio: false
        });

        // Set the stream on the video element.
        videoElement.srcObject = stream;

        // Start the video element.
        videoElement.play();

        // Return the stream.
        return stream;
    }

    async stopStream(videoElement: HTMLVideoElement, stream: MediaStream): Promise<void> {
        // Stop the video element.
        videoElement.pause();

        // Close the stream.
        let tracks = stream.getTracks();
        tracks.forEach(track => track.stop());
    }

    setCapturedImage(type: 'dashboard' | 'odometer', image: CameraOutputData | null) {
      this.capturedImageSubject.next({
        ...this.capturedImageSubject.value,
        [type]: image
      });
    }
}
